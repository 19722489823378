import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import './i18n';
import OverrideService from './@launchpad/core/overrides/OverrideService';
import styles from './@baseProject/styles/index';

// Main has to be imported as lazy component to be able to inject style from @baseProject
const Main = React.lazy(() => import('./@baseProject/index'));
OverrideService.setStyles(styles);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <Suspense fallback={<div />}>
    <Main />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
