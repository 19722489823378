import colors from './variables.module.scss';

const styles = {
  ...colors,
  MENU_HEADER_HEIGHT: 58,
  LOGO: require('../assets/logo-dark.png'),
  PROFILE_IMAGE: require('../assets/no_profile_image.jpeg'),
};

export default styles;
